<template>
  <div class="Result" :class="{ ResultB: isResultB, screenshot: isScreenshot }">
    <div
      v-if="resultContent.type === 'A'"
      class="role_wrap_a"
      :style="`background-image: url('/static/img/survey/${resultContent.name}/frame.png')`"
    >
      <img class="ultimate" :src="ultimateSrc" alt="" />
      <img
        class="role"
        :src="`/static/img/survey/${resultContent.name}/role.png`"
        alt=""
      />
      <img
        class="profession"
        :src="`/static/img/survey/${resultContent.name}/text_${$i18n.locale}.png`"
        alt=""
      />
      <template>
        <img
          v-if="resultContent.redSignature"
          class="signature"
          src="@/assets/image/survey/result/signature_red.png"
          alt=""
        />
        <img
          v-else
          class="signature"
          src="@/assets/image/survey/result/signature_yellow.png"
          alt=""
        />
      </template>
    </div>

    <div
      v-else
      class="role_wrap_b"
      :style="`background-image: url('/static/img/survey/${resultContent.name}/frame.png')`"
    >
      <img class="ultimate" :src="ultimateSrc" alt="" />
      <img
        class="revolver"
        src="@/assets/image/survey/result/result_b/icon_revolver.png"
        alt=""
      />
      <img
        class="illustration"
        :src="`/static/img/survey/${resultContent.name}/Illustration.png`"
        alt=""
      />
      <div class="role_container">
        <div class="role_wrap">
          <img
            class="role"
            :src="`/static/img/survey/${resultContent.name}/role.png`"
            alt=""
          />
        </div>
        <img
          class="signature"
          src="@/assets/image/survey/result/signature_yellow.png"
          alt=""
        />
        <img
          class="stamp"
          :src="`/static/img/survey/${resultContent.name}/stamp.png`"
          alt=""
        />
        <img
          class="profession"
          :src="`/static/img/survey/${resultContent.name}/text_${$i18n.locale}.png`"
          alt=""
        />
      </div>
    </div>
    <div class="desc">
      <div class="corner l_t"></div>
      <div class="corner r_t"></div>
      <div class="corner r_b"></div>
      <div class="corner l_b"></div>
      <div class="content">{{ $t(resultContent.desc) }}</div>
    </div>
    <div class="btns" :class="currentLang">
      <a href="javascript:;" @click="retest">
        <img
          src="@/assets/image/survey/result/btn/drh5_icon_restart.png"
          alt=""
        />
        <span class="text">{{ $t("survey_bg.p16") }}</span>
      </a>
      <a href="javascript:;" @click="downloadResult">
        <img src="@/assets/image/survey/result/btn/drh5_icon_save.png" alt="" />
        <span class="text">{{ $t("survey_bg.p17") }}</span>
      </a>
      <a href="javascript:;" @click="share">
        <img
          src="@/assets/image/survey/result/btn/drh5_icon_share.png"
          alt=""
        />
        <span class="text">{{ $t("survey_bg.p18") }}</span>
      </a>
    </div>
    <CommonDialog v-model="shareDialog" className="share_dialog">
      <img class="title_img" slot="title" :src="dialogTitleSrc" alt="" />
      <ul class="share_list" slot="content">
        <li v-for="(item, index) in shareList" :key="index">
          <a href="javascript:;" @click="openShareWindow(item)">
            <img class="icon" :src="item.icon" alt="" />
            <div class="label">{{ item.label }}</div>
          </a>
        </li>
      </ul>
    </CommonDialog>
    <CommonDialog
      v-model="getAwardSuccessDialog"
      className="get_award_success_dialog"
    >
      <div class="get_award_content" slot="content">
        <img
          class="header_img"
          slot="title"
          src="@/assets/image/survey/result/drh5_popwin2_img.png"
          alt=""
        />
        <div class="text">{{ $t("survey_bg.p32") }}</div>
        <a href="javascript:;" class="ok" @click="getAwardSuccessDialog = false"
          >OK</a
        >
      </div>
    </CommonDialog>
    <CommonDialog
      v-model="saveImgDialog"
      overlay-opacity="0.8"
      className="save_img_dialog"
    >
      <div class="save_img_content" slot="content">
        <img class="header_img" slot="title" :src="downloadUrl" alt="" />
      </div>
    </CommonDialog>
    <v-snackbar
      v-model="shareSuccessMsg"
      :centered="true"
      :timeout="5000"
      color="rgba(0, 0, 0, 0.6)"
    >
      <div class="share_success_msg">
        {{ $t("survey_bg.p21") }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="saveSuccessMsg"
      :centered="true"
      :timeout="5000"
      color="rgba(0, 0, 0, 0.6)"
    >
      <div class="share_success_msg">
        {{ $t("survey_bg.p20") }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import DeviceDetector from "device-detector-js";
import html2canvas from "html2canvas";
import { resultList } from "../utils/data";
import CommonDialog from "./CommonDialog.vue";
import share from "../mixins/share";
export default {
  name: "Result",
  mixins: [share],
  components: {
    CommonDialog,
  },
  props: {
    resultId: {
      type: Number,
      required: true,
    },
    isScreenshot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saveImgDialog: false,
      shareSuccessMsg: false,
      saveSuccessMsg: false,
      downloadUrl: "",
    };
  },
  computed: {
    isResultB() {
      const currentResult =
        resultList.find((item) => item.id === this.resultId) || {};
      return currentResult.type === "B" || currentResult.type === "C";
    },
    resultContent() {
      return resultList.find(({ id }) => id === this.resultId);
    },
    ultimateSrc() {
      if (this.isResultB) {
        return require(`@/assets/image/survey/result/result_b/ultimate/${this.$i18n.locale}.png`);
      }
      return require(`@/assets/image/survey/result/ultimate/${this.$i18n.locale}.png`);
    },
    dialogTitleSrc() {
      return require(`@/assets/image/survey/result/share/drh5_share_${this.$i18n.locale}.png`);
    },
    currentLang() {
      return this.$i18n.locale;
    },
    // downloadUrl() {
    //   const version = this.resultContent.version
    //     ? `_${this.resultContent.version}`
    //     : "";
    //   return `https://d3qgi0t347dz44.cloudfront.net/release/video/${this.resultContent.name}_jp${version}.png`;
    // },
  },
  methods: {
    retest() {
      this.$emit("retest");
    },
    downloadResult() {
      this.$emit("screenshot");
      this.$nextTick(async () => {
        html2canvas(document.getElementById("screenshot_node"))
          .then((canvas) => {
            const dataUrl = canvas.toDataURL("image/png");
            // 设备检测
            const deviceDetector = new DeviceDetector();
            const device = deviceDetector.parse(navigator.userAgent);
            if (device.os?.name === "iOS") {
              this.downloadUrl = dataUrl;
              this.saveImgDialog = true;
              return;
            } else {
              const link = document.createElement("a");
              link.download = `${this.resultContent.name}.png`;
              link.href = dataUrl;
              link.click();
              link.remove();
            }
          })
          .then(() => {
            this.$emit("screenshoted");
          })
          .catch(function (error) {
            alert(`screenshot error : ${error}`);
          });
        /*     if (isIOS) {
          this.saveImgDialog = true;
        } else {
          // 非 iOS 设备
          const response = await axios({
            url: this.downloadUrl,
            method: "GET",
            responseType: "blob",
          });
          saveAs(response.data, `${this.resultContent.name}_jp.png`);
          this.saveSuccessMsg = true;
        } */
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Result {
  color: #fff;
  .role_wrap_a {
    position: relative;
    width: 75rem;
    height: 112rem;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 17rem;
    margin-top: -25rem;
    .ultimate {
      width: 32.8rem;
      margin: 0 auto;
    }
    .role {
      width: 38rem;
      margin: 0 auto;
    }
    .profession {
      position: relative;
      z-index: 1;
      width: 66rem;
      margin: 0 auto;
    }
    .signature {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -100%);
      width: 53.5rem;
    }
  }
  .desc {
    position: relative;
    width: 73rem;
    background-color: rgba(0, 0, 0, 0.65);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 1.4rem 1.4rem 0 rgba(0, 0, 0, 0.3);
    padding: 5rem 2.4rem;
    margin: -20rem auto 2.4rem;
    .content {
      font-size: 2.4rem;
      line-height: 4.8rem;
      color: #ffffff;
      text-align: center;
      white-space: pre-line;
    }
    .corner {
      width: 4rem;
      height: 4rem;
      // background-image: url("../../../assets/image/survey/result/desc_corner.png");
      background-size: cover;
      background-color: rgba(0, 0, 0, 0);
      position: absolute;
      border-top: 0.6rem solid #fff;
      border-left: 0.6rem solid #fff;
      &.l_t {
        left: 0;
        top: 0;
      }
      &.r_t {
        right: 0;
        top: 0;
        transform: rotate(90deg);
      }
      &.r_b {
        right: 0;
        bottom: 0;
        transform: rotate(180deg);
      }
      &.l_b {
        left: 0;
        bottom: 0;
        transform: rotate(-90deg);
      }
    }
  }
  .btns {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0 2.5rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20rem;
      height: 5.5rem;
      line-height: 5.5rem;
      background-image: url("../../../assets/image/survey/result/btn/drh5_btn_frame.png");
      background-size: cover;
      margin-right: 1.2rem;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 2.8rem;
        margin-right: 1rem;
      }
      .text {
        font-size: 2.5rem;
        color: #ffffff;
      }
    }
    &.en {
      .text {
        font-size: 2rem;
        color: #ffffff;
      }
    }
  }
  &.ResultB {
    .role_wrap_b {
      position: relative;
      width: 75rem;
      height: 128.3rem;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 6rem;
      margin-top: -20rem;
      .ultimate {
        width: 25.5rem;
        margin-left: 29rem;
      }
      .illustration {
        position: absolute;
        top: 8.5rem;
        left: -8rem;
        width: 69rem;
        height: 128rem;
        z-index: 1;
      }
      .revolver {
        position: absolute;
        left: 2rem;
        top: 10rem;
        width: 10.8rem;
      }
      .role_container {
        position: absolute;
        top: -8rem;
        left: 1rem;
        width: 75rem;
        height: 80rem;
        background-image: url("../../../assets/image/survey/result/result_b/drh5_bg_circle.png");
        background-size: cover;
        .role_wrap {
          width: 17rem;
          height: 17rem;
          position: absolute;
          top: 25.6rem;
          left: 49.7rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .role {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .stamp {
          position: absolute;
          top: 47rem;
          left: 46rem;
          width: 18.8rem;
        }
        .signature {
          position: absolute;
          top: 48rem;
          left: 38rem;
          z-index: 1;
          width: 53.5rem;
        }
        .profession {
          position: absolute;
          top: 50rem;
          left: 40rem;
          z-index: 2;
          width: 35rem;
        }
      }
    }
    .desc {
      margin: -55rem auto 2.4rem;
      z-index: 2;
    }
    .btns {
      position: relative;
      z-index: 2;
    }
  }
  &.screenshot {
    .btns {
      visibility: hidden;
    }
  }
}
</style>
<style lang="scss">
.share_dialog {
  .title_img {
    width: 46rem;
  }
  .share_list {
    padding: 0 13.5rem;
    li {
      margin-top: -1.8rem;
      a {
        position: relative;
        .icon {
          position: relative;
          z-index: 1;
          width: 16rem;
        }
        .label {
          width: 30.9rem;
          height: 6.4rem;
          line-height: 6.4rem;
          position: absolute;
          left: 11rem;
          top: 6rem;
          background-image: url("../../../assets/image/survey/result/share/drh5_btn_share.png");
          background-size: cover;
          background-repeat: no-repeat;
          padding-left: 3rem;
          text-align: center;
          font-size: 4rem;
          color: #ffffff;
        }
      }
    }
  }
}
.get_award_success_dialog {
  .get_award_content {
    position: relative;
    height: 100%;
    .header_img {
      display: block;
      width: 72.4rem;
      margin-bottom: 8.3rem;
      margin-left: 2rem;
    }
    .text {
      font-size: 3.2rem;
      line-height: 5rem;
      color: #ffffff;
      text-align: center;
      padding: 0 10.7rem;
    }
    .ok {
      position: absolute;
      left: 50%;
      bottom: 18rem;
      transform: translateX(-50%);
      width: 29.8rem;
      height: 8.9rem;
      line-height: 8.9rem;
      text-align: center;
      background-image: url("../../../assets/image/survey/result/drh5_btn_ok.png");
      background-size: cover;
      background-repeat: no-repeat;
      font-size: 4rem;
      color: #ffffff;
    }
  }
}
.save_img_dialog {
  .survey_dialog_body {
    .save_img_content {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-left: 1.5rem;
      img {
        width: 48rem;
      }
    }
  }
}
.v-snack__wrapper {
  width: 50rem;
  border-radius: 0 !important;
  .v-snack__content {
    padding-top: 0;
    padding-bottom: 0;
  }
  .share_success_msg {
    height: 7rem;
    line-height: 7rem;
    font-size: 3rem;
    color: #ffffff;
    text-align: center;
  }
}
</style>
