<template>
  <div class="Entrance">
    <img class="title" :src="titleSrc" alt="" />
    <div class="start_test_wrap" :class="$i18n.locale">
      <a href="javascript:;" @click="startTest">{{ $t("survey_bg.p13") }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Entrance",
  computed: {
    titleSrc() {
      return require(`@/assets/image/survey/entrance/drh5_title_${this.$i18n.locale}.png`);
    },
  },
  methods: {
    startTest() {
      this.$emit("startTest");
    },
  },
};
</script>
<style lang="scss" scoped>
.Entrance {
  .title {
    width: 57rem;
    margin-left: 1.5rem;
    margin-bottom: 2rem;
  }
  .start_test_wrap {
    width: 62.3rem;
    height: 43.4rem;
    background-image: url("../../../assets/image/survey/entrance/drh5_btn_continue.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    padding-top: 16rem;
    a {
      height: 8rem;
      line-height: 8rem;
      font-size: 5rem;
      color: #ffffff;
      font-weight: 900;
      text-shadow: 0px 0px 1rem rgba(255, 13, 47, 0.5),
        0px 0px 1rem rgba(255, 13, 47, 0.5);
      text-align: center;
      padding-left: 4.8rem;
      padding-right: 8rem;
      letter-spacing: 0.8rem;
    }
    &.en {
      a {
        letter-spacing: 0;
      }
    }
  }
}
</style>
