<template>
  <div v-if="refresh">
    <div v-if="isNotStart && !isAllowAccess">
      <Permission @pass="isAllowAccess = true"></Permission>
    </div>
    <div v-else-if="isExpried">
      <Expired></Expired>
    </div>
    <div
      v-else
      id="screenshot_node"
      class="Survey"
      :class="[currentState, { result_b: isResultB }]"
      :style="{ height: pageHeight }"
    >
      <Header :isScreenshot="isScreenshot" />
      <main :style="{ height: `calc(${pageHeight} - 9rem)` }">
        <Entrance
          v-if="currentState === pageState.Entrance"
          @startTest="startTest"
        ></Entrance>
        <StartTest
          v-if="currentState === pageState.StartTest"
          @toDoTest="toDoTest"
        ></StartTest>
        <DoTest
          v-if="currentState === pageState.DoTest"
          :nextTo16="nextTo16"
          @toResult="toResult"
          @sendLog="sendLog"
        ></DoTest>
        <Result
          v-if="currentState === pageState.Result"
          :resultId="resultId"
          :isScreenshot="isScreenshot"
          @retest="retest"
          @sendLog="sendLog"
          @screenshot="screenshot"
          @screenshoted="screenshoted"
        ></Result>
      </main>
      <div class="copyright">
        ©Formirai Co., Ltd. ©Spike Chunsoft Co., Ltd. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./comp/Header.vue";
import Entrance from "./comp/Entrance.vue";
import StartTest from "./comp/StartTest.vue";
import DoTest from "./comp/DoTest.vue";
import Result from "./comp/Result.vue";
import Permission from "./comp/Permission.vue";
import Expired from "./comp/Expired.vue";
import { pageState, resultList } from "./utils/data";
import SlsTracker from "@aliyun-sls/web-track-browser";
import { logConfig } from "./utils/logConfig";
import DeviceDetector from "device-detector-js";
export default {
  name: "Survey",
  components: {
    Header,
    Entrance,
    StartTest,
    DoTest,
    Result,
    Permission,
    Expired,
  },
  data() {
    return {
      refresh: true,
      isAllowAccess: false,
      isScreenshot: false,
      resultId: 0,
      pageState,
      currentState: pageState.Entrance,
      nextTo16: false,
      tracker: null,
      logParams: null,
      pageHeight: "100vh",
    };
  },
  computed: {
    isResultB() {
      const currentResult = resultList.find(
        (item) => item.id === this.resultId
      );
      if (!currentResult) return false;
      return currentResult.type === "B" || currentResult.type === "C";
    },
    activity() {
      return this.$store.state.activityList.find(
        (item) => item.activityType === 241
      );
    },
    isExpried() {
      return this.$store.state.serverTime > this.activity.receiveEndTime;
    },
    isNotStart() {
      if (!this.activity) return false;
      return this.$store.state.serverTime < this.activity.receiveStartTime;
    },
  },
  created() {
    this.setHeight();
  },
  mounted() {
    this.initSendLog();
    window.addEventListener("resize", this.handleRefresh);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleRefresh);
  },
  methods: {
    handleRefresh() {
      this.setHeight();
      // this.refresh = false;
      // this.$nextTick(() => {
      //   this.refresh = true;
      //   this.setHeight();
      // });
    },
    setHeight() {
      this.pageHeight = `${window.innerHeight}px`;
    },
    initSendLog() {
      // 日志对象
      this.tracker = new SlsTracker(logConfig);
      // 设备检测
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      this.logParams = {
        platform: "10",
        os_ver: `${device.device.model} (${device.device.brand})`,
        os: device.os.name,
        f1: "3031",
        f2: "1",
      };
      if (
        this.$route.query.uid &&
        this.$route.query.token &&
        this.$route.query.uid.length < 20
      ) {
        this.logParams.user_id = this.$route.query.uid;
      }
      this.sendLog({
        f3: "0",
        f4: "0",
        f5: "0",
      });
    },
    startTest() {
      this.currentState = pageState.StartTest;
      this.sendLog({
        f3: "0",
        f4: "0",
        f5: "1",
      });
    },
    toDoTest() {
      this.currentState = pageState.DoTest;
      this.sendLog({
        f3: "0",
        f4: "0",
        f5: "2",
      });
    },
    toResult(resultId) {
      this.currentState = pageState.Result;
      this.resultId = resultId;
      this.nextTo16 = resultId === 17;
      this.sendLog({
        f3: "1",
        f4: "0",
        f5: `${resultId}`,
      });
    },
    retest() {
      this.sendLog({
        f3: "0",
        f4: "1",
        f5: "4",
      });
      if (this.nextTo16) {
        this.currentState = pageState.DoTest;
        this.sendLog({
          f3: "0",
          f4: "0",
          f5: "6",
        });
      } else {
        this.currentState = pageState.Entrance;
      }
    },
    sendLog(log) {
      console.log("log:", { ...this.logParams, ...log });
      this.tracker.send({
        ...this.logParams,
        ...log,
      });
    },
    screenshot() {
      this.isScreenshot = true;
    },
    screenshoted() {
      this.isScreenshot = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.Survey {
  position: relative;
  width: 100%;
  max-width: 75rem;
  // max-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  background-image: url("../../assets/image/survey/entrance/drh5_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #000;
  &.StartTest {
    background-image: url("../../assets/image/survey/start_test/drh5_popwin_bg.png");
  }
  &.DoTest {
    background-image: url("../../assets/image/survey/do_test/drh5_bg.png");
  }
  &.Result {
    background-image: url("../../assets/image/survey/result/drh5_bg.png");
    &.result_b {
      background-image: url("../../assets/image/survey/result/result_b/drh5_bg.png");
    }
  }
  main {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .copyright {
    position: absolute;
    left: 0;
    bottom: 1rem;
    z-index: 9;
    width: 100%;
    font-family: SourceHanSansSC-Bold;
    font-size: 2rem;
    color: #ffffff;
    opacity: 0.3;
    text-align: center;
  }
}
</style>
<style lang="scss">
.v-application--wrap {
  background-color: #000;
  min-height: auto;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.Survey,
.survey_dialog {
  a,
  img {
    display: block;
  }
  img {
    max-width: 100%;
  }
}
</style>
