<template>
  <div class="Permission">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="password"
        :rules="passwordRules"
        label="Password"
        required
      ></v-text-field>
      <v-btn
        :disabled="!valid"
        :loading="loading"
        color="success"
        class="mr-4"
        @click="confirm"
      >
        Confirm
      </v-btn>
    </v-form>
    <v-snackbar v-model="wrongPassword">
      <p style="font-size: 3rem">wrong Password</p>
    </v-snackbar>
  </div>
</template>

<script>
import { webShareCheckAuth } from "../utils/api";
export default {
  password: "Permission",
  data: () => ({
    valid: true,
    password: "",
    passwordRules: [(v) => !!v || "Password is required"],
    wrongPassword: false,
    loading: false,
  }),
  methods: {
    async confirm() {
      if (!this.$refs.form.validate()) return;
      try {
        this.loading = true;
        const res = await webShareCheckAuth(this.password);
        if (!res.data.pass) {
          this.wrongPassword = true;
          return;
        }
        this.$emit("pass");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Permission {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  background: #fff;
  padding: 0 2rem;
  .v-form {
    width: 100%;
    text-align: center;
  }
  .v-btn {
    font-size: 3.6rem;
  }
}
</style>
