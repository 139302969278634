<template>
  <header :class="{ screenshot: isScreenshot }">
    <router-link to="/" class="logo">
      <img :src="logoSrc" alt="" />
    </router-link>
    <router-link to="/" class="to_home">
      <img
        src="@/assets/image/survey/header/drh5_btn_officalwebsite_jp.png"
        alt=""
      />
    </router-link>
    <a href="javascript:;" class="voice" @click="switchAudio">
      <img
        v-if="!isPaused"
        src="@/assets/image/survey/header/drh5_btn_bgmon.png"
        alt=""
      />
      <img
        v-else
        src="@/assets/image/survey/header/drh5_btn_bgmoff.png"
        alt=""
      />
    </a>
    <a href="javascript:;" class="lang" @click="langDialog = true">
      <img :src="currentLangIcon" alt="" />
    </a>
    <CommonDialog v-model="langDialog">
      <img class="title_img" slot="title" :src="langDialogTitleSrc" alt="" />
      <ul class="lang_list" slot="content">
        <li
          v-for="(item, index) in langList"
          :key="index"
          :class="{ active: item.value === currentLang }"
        >
          <a href="javascript:;" @click="handleSetLanguage(item.value)">
            <div class="left">
              <img class="icon" :src="item.icon" alt="" />
              <span class="label">{{ item.label }}</span>
            </div>
            <div class="check">
              <img
                src="@/assets/image/survey/dialog/lang/drh5_check.png"
                alt=""
              />
              <img src="" alt="" />
            </div>
          </a>
        </li>
      </ul>
    </CommonDialog>
  </header>
</template>

<script>
import "../utils/flexible.js";
import Cookies from "js-cookie";
import CommonDialog from "./CommonDialog.vue";
export default {
  components: {
    CommonDialog,
  },
  props: {
    isScreenshot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audio: null,
      isPaused: true,
      audioSrc:
        "https://d3qgi0t347dz44.cloudfront.net/release/video/HS_BGM_000_DANGANRONPA_01.mp3",
      langDialog: false,
    };
  },
  computed: {
    langList() {
      return [
        {
          label: "English",
          value: "en",
          icon: require("@/assets/image/survey/dialog/lang/drh5_btn_language_en.png"),
        },
        {
          label: "日本語",
          value: "jp",
          icon: require("@/assets/image/survey/dialog/lang/drh5_btn_language_jp.png"),
        },
        {
          label: "繁體中文",
          value: "tc",
          icon: require("@/assets/image/survey/dialog/lang/drh5_btn_language_tc.png"),
        },
        {
          label: "简体中文",
          value: "sc",
          icon: require("@/assets/image/survey/dialog/lang/drh5_btn_language_sc.png"),
        },
        {
          label: "한국어",
          value: "kr",
          icon: require("@/assets/image/survey/dialog/lang/drh5_btn_language_kr.png"),
        },
      ];
    },
    currentLang() {
      return this.$i18n.locale;
    },
    currentLangIcon() {
      return this.langList.find((item) => item.value === this.currentLang).icon;
    },
    langDialogTitleSrc() {
      return require(`@/assets/image/survey/dialog/lang/drh5_language_${this.$i18n.locale}.png`);
    },
    logoSrc() {
      return require(`@/assets/image/survey/header/logo_${this.$i18n.locale}.png`);
    },
  },
  created() {
    this.setDocumentTitle();
  },
  beforeDestroy() {
    this.stopAudio();
  },
  watch: {
    $route() {
      this.stopAudio();
    },
  },
  methods: {
    handleSetLanguage(lang) {
      Cookies.set("language", lang, { expires: 30 });
      this.$store.dispatch("setLanguage", lang);
      this.$i18n.locale = lang;
      this.langDialog = false;
      this.setDocumentTitle();
    },
    setDocumentTitle() {
      document.title = this.$t("survey_bg.p48");
    },
    createAudio() {
      if (this.audio) return;
      this.audio = new Audio(this.audioSrc);
      this.audio.loop = true;
      this.audio.play();
      this.isPaused = false;
    },
    switchAudio() {
      if (!this.audio) {
        this.createAudio();
        return;
      }
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
      this.isPaused = this.audio.paused;
    },
    stopAudio() {
      if (!this.audio) return;
      this.audio.pause(); // 停止播放音频
      this.audio.src = ""; // 移除音频源
      this.audio = null; // 解除引用
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  position: relative;
  z-index: 10;
  display: flex;
  padding-top: 1.7rem;
  align-items: center;
  .logo {
    width: 35.2rem;
    margin-left: 2rem;
  }
  .to_home {
    width: 18.1rem;
    margin-left: 3.8rem;
  }
  .voice {
    width: 5.7rem;
    margin-left: 1rem;
  }
  .lang {
    width: 5.7rem;
    margin-left: 1rem;
  }
  &.screenshot {
    .to_home,
    .voice,
    .lang {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
.survey_dialog {
  .title_img {
    width: 42rem;
  }
  .lang_list {
    padding: 0 8.6rem;
    li {
      padding-left: 5.4rem;
      padding-right: 2.5rem;
      border-bottom: 2px dashed #3a3939;
      &:last-child {
        border-bottom: none;
      }
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 14rem;
        .left {
          display: flex;
          .icon {
            width: 5.7rem;
            margin-right: 5rem;
          }
          .label {
            font-size: 4rem;
            color: #a9a9a9;
            font-family: SourceHanSansSC-Bold;
            font-weight: bold;
          }
        }
        .check {
          position: relative;
          width: 5.6rem;
          height: 5.4rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url("../../../assets/image/survey/dialog/lang/drh5_dot.png");
          img {
            position: absolute;
            left: 1rem;
            top: 0rem;
            width: 5.6rem;
            display: none;
          }
        }
      }

      &.active {
        .left {
          .label {
            color: #ffffff;
          }
        }
        .check {
          img {
            display: block;
          }
        }
      }
    }
  }
}
</style>
