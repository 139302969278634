export const pageState = {
  Entrance: "Entrance",
  StartTest: "StartTest",
  DoTest: "DoTest",
  Result: "Result",
};

export const surveyList = [
  {
    id: 1,
    question: "p2",
    options: [
      { id: 1, label: "p3", next: 2 },
      { id: 2, label: "p4", next: 9 },
    ],
  },
  {
    id: 2,
    question: "p5",
    options: [
      { id: 3, label: "p6", next: 3 },
      { id: 4, label: "p7", next: 4 },
    ],
  },
  {
    id: 3,
    question: "p8",
    options: [
      { id: 5, label: "p9", next: 5 },
      { id: 6, label: "p10", next: 6 },
    ],
  },
  {
    id: 4,
    question: "p11",
    options: [
      { id: 7, label: "p12", next: 7 },
      { id: 8, label: "p13", next: 8 },
    ],
  },
  {
    id: 5,
    question: "p14",
    options: [
      { id: 9, label: "p15", result: 1 },
      { id: 10, label: "p16", result: 2 },
    ],
  },
  {
    id: 6,
    question: "p17",
    options: [
      { id: 11, label: "p18", result: 3 },
      { id: 12, label: "p19", result: 4 },
    ],
  },
  {
    id: 7,
    question: "p20",
    options: [
      { id: 13, label: "p21", result: 5 },
      { id: 14, label: "p22", result: 6 },
      { id: 15, label: "p23", result: 7 },
    ],
  },
  {
    id: 8,
    question: "p24",
    options: [
      { id: 16, label: "p25", result: 8 },
      { id: 17, label: "p26", result: 9 },
    ],
  },

  {
    id: 9,
    question: "p27",
    options: [
      { id: 18, label: "p28", next: 10 },
      { id: 19, label: "p29", next: 11 },
    ],
  },
  {
    id: 10,
    question: "p30",
    options: [
      { id: 20, label: "p31", next: 12 },
      { id: 21, label: "p32", next: 13 },
    ],
  },
  {
    id: 11,
    question: "p33",
    options: [
      { id: 22, label: "p34", next: 14 },
      { id: 23, label: "p35", next: 15 },
    ],
  },

  {
    id: 12,
    question: "p36",
    options: [
      { id: 24, label: "p37", result: 10 },
      { id: 25, label: "p38", result: 11 },
    ],
  },
  {
    id: 13,
    question: "p39",
    options: [
      { id: 26, label: "p40", result: 12 },
      { id: 27, label: "p41", result: 13 },
    ],
  },
  {
    id: 14,
    question: "p42",
    options: [
      { id: 28, label: "p43", result: 14 },
      { id: 29, label: "p44", result: 15 },
    ],
  },
  {
    id: 15,
    question: "p45",
    options: [
      { id: 30, label: "p46", result: 16 },
      { id: 31, label: "p47", result: 17 },
    ],
  },
  {
    id: 16,
    question: "p48",
    options: [
      { id: 32, label: "p49", result: 18 },
      { id: 33, label: "p50", result: 19 },
    ],
  },
];
/*
Martial_Artist
Swimming_Pro
Biker_Gang_Leader
Baseball_Star
Moral_Compass
Programmer
Ultimate
Fanfic_Creator
Writing_Prodigy
Gambler
Affluent_Progeny
Clairvoyant
Pop_Sensation
Soldier
Lucky_Student
Fashionista
Despair
*/

export const resultList = [
  {
    id: 1,
    profession: "survey_profession.p2", //格斗家
    result: "survey_result.p2",
    name: "Martial_Artist",
    desc: "survey_result.p3",
    type: "A",
    version: "v3",
  },
  {
    id: 2,
    profession: "survey_profession.p3", //游泳选手
    result: "survey_result.p4",
    name: "Swimming_Pro",
    desc: "survey_result.p5",
    type: "A",
    version: "v4",
    redSignature: true,
  },
  {
    id: 3,
    profession: "survey_profession.p4", //飙车族
    result: "survey_result.p6",
    name: "Biker_Gang_Leader",
    desc: "survey_result.p7",
    type: "A",
    version: "v3",
  },
  {
    id: 4,
    profession: "survey_profession.p5", //棒球选手
    result: "survey_result.p8",
    name: "Baseball_Star",
    desc: "survey_result.p9",
    type: "A",
    version: "v4",
    redSignature: true,
  },
  {
    id: 5,
    profession: "survey_profession.p6", //风纪委员
    result: "survey_result.p10",
    name: "Moral_Compass",
    desc: "survey_result.p11",
    type: "A",
    version: "v3",
  },
  {
    id: 6,
    profession: "survey_profession.p7", //程序员
    result: "survey_result.p12",
    name: "Programmer",
    desc: "survey_result.p13",
    type: "A",
    version: "v3",
  },
  {
    id: 7,
    profession: "survey_profession.p8", //？？？
    result: "survey_result.p14",
    name: "Ultimate",
    desc: "survey_result.p15",
    type: "B",
    version: "v3",
  },
  {
    id: 8,
    profession: "survey_profession.p9", //同人作家
    result: "survey_result.p16",
    name: "Fanfic_Creator",
    desc: "survey_result.p17",
    type: "A",
    version: "v3",
  },
  {
    id: 9,
    profession: "survey_profession.p10", //文学少女
    result: "survey_result.p18",
    name: "Writing_Prodigy",
    desc: "survey_result.p19",
    type: "A",
    version: "v3",
  },
  {
    id: 10,
    profession: "survey_profession.p11", //赌徒
    result: "survey_result.p20",
    name: "Gambler",
    desc: "survey_result.p21",
    type: "B",
    version: "v3",
  },
  {
    id: 11,
    profession: "survey_profession.p12", //名门公子
    result: "survey_result.p22",
    name: "Affluent_Progeny",
    desc: "survey_result.p23",
    type: "A",
    version: "v3",
  },
  {
    id: 12,
    profession: "survey_profession.p13", //占卜师
    result: "survey_result.p24",
    name: "Clairvoyant",
    desc: "survey_result.p25",
    type: "A",
    version: "v3",
  },
  {
    id: 13,
    profession: "survey_profession.p14", //偶像
    result: "survey_result.p26",
    name: "Pop_Sensation",
    desc: "survey_result.p27",
    type: "A",
    version: "v3",
  },
  {
    id: 14,
    profession: "survey_profession.p15", //军人
    result: "survey_result.p28",
    name: "Soldier",
    desc: "survey_result.p29",
    type: "A",
    version: "v3",
  },
  {
    id: 15,
    profession: "survey_profession.p16", //幸运
    result: "survey_result.p30",
    name: "Lucky_Student",
    desc: "survey_result.p31",
    type: "B",
    version: "v3",
  },
  {
    id: 16,
    profession: "survey_profession.p17", //辣妹
    result: "survey_result.p32",
    name: "Fashionista",
    desc: "survey_result.p33",
    type: "A",
    version: "v3",
  },
  {
    id: 17,
    profession: "survey_profession.p17", //辣妹
    result: "survey_result.p32",
    name: "Fashionista",
    desc: "survey_result.p33",
    type: "A",
    version: "v3",
  },
  {
    id: 18,
    profession: "survey_profession.p18", //绝望
    result: "survey_result.p34",
    name: "Despair",
    desc: "survey_result.p35",
    type: "C",
    version: "v3",
  },
  {
    id: 19,
    profession: "survey_profession.p18", //绝望
    result: "survey_result.p34",
    name: "Despair",
    desc: "survey_result.p35",
    type: "C",
    version: "v3",
  },
];
