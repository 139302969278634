import { shareConfig } from "../utils/config";
import { webShareGetAward } from "../utils/api";
export default {
  data() {
    return {
      shareDialog: false,
      getAwardSuccessDialog: false,
    };
  },
  computed: {
    shareContent() {
      return {
        ...shareConfig,
        title: encodeURIComponent(this.$t(shareConfig.title)),
        text: encodeURIComponent(
          `${this.$t(shareConfig.text_1)}${this.$t(
            this.resultContent.result
          )}${this.$t(shareConfig.text_2)}`
        ),
        textWithUrl: encodeURIComponent(
          `${this.$t(shareConfig.text_1)}${this.$t(
            this.resultContent.result
          )}${this.$t(shareConfig.text_2)} ${shareConfig.domain}`
        ),
        tags: encodeURIComponent(`${this.$t(shareConfig.tag_1)},${this.$t(shareConfig.tag_2)}`),
        tagsWithsign: `#${this.$t(shareConfig.tag_1)}`,
      };
    },
    shareList() {
      const { title, text, textWithUrl, url, url_cn, tags } = this.shareContent;
      return [
        {
          label: "X",
          icon: require("@/assets/image/survey/result/share/drh5_icon_x.png"),
          intentUrl: `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${tags}`,
          value: "x",
        },
        {
          label: "Facebook",
          icon: require("@/assets/image/survey/result/share/drh5_icon_fb.png"),
          intentUrl: "fb",
          value: "fb",
        },
        {
          label: "Line",
          icon: require("@/assets/image/survey/result/share/drh5_icon_line.png"),
          intentUrl: `https://social-plugins.line.me/lineit/share?url=${url}&text=${text}`,
          value: "line",
        },
        {
          label: "Whatsapp",
          icon: require("@/assets/image/survey/result/share/drh5_icon_whatsapp.png"),
          intentUrl: `https://api.whatsapp.com/send?text=${textWithUrl}`,
          value: "whatsapp",
        },
        {
          label: this.$t("survey_bg.p25"),
          icon: require("@/assets/image/survey/result/share/drh5_icon_weibo.png"),
          intentUrl: `https://service.weibo.com/share/share.php?url=${url_cn}&title=${title}`,
          value: "weibo",
        },
      ];
    },
  },
  methods: {
    share() {
      this.shareDialog = true;
      this.$emit("sendLog", {
        f3: "0",
        f4: "0",
        f5: "7",
      });
    },
    openShareWindow(item) {
      this.shareSendLog(item.value);
      this.getShareAward();
      this.shareDialog = false;
      if (item.intentUrl === "fb") {
        this.facebookShare();
        return;
      }
      window.open(item.intentUrl, "_blank", "width=550,height=500");
    },
    facebookShare() {
      const { pureUrl, tagsWithsign } = this.shareContent;
      window.FB.ui(
        {
          method: "share",
          hashtag: tagsWithsign,
          href: pureUrl,
          display: "popup",
          redirect_uri: pureUrl, //用户在完成 Facebook 对话框操作后跳转的 URL 2024-05-13Facebook文档显示已弃用该配置
        },
        function (response) {
          console.log("response:", response);
        }
      );
    },
    async getShareAward() {
      try {
        const { uid, token } = this.$route.query;
        if (!uid || !token) return;
        const params = {
          token,
          userID: uid,
          activityID: 241001,
        };
        const res = await webShareGetAward(params);
        if (res.data.code !== 0) return;
        if (!res.data.award) return;
        this.getAwardSuccessDialog = true;
      } catch (e) {
        console.log(e);
      } finally {
        if (!this.getAwardSuccessDialog) {
          this.shareSuccessMsg = true;
        }
      }
    },
    shareSendLog(value) {
      const fMap = {
        x: { f4: "0", f5: "9" },
        fb: { f4: "1", f5: "1" },
        line: { f4: "1", f5: "2" },
        whatsapp: { f4: "1", f5: "3" },
        weibo: { f4: "0", f5: "8" },
      };
      this.$emit("sendLog", {
        f3: "0",
        f4: fMap[value].f4,
        f5: fMap[value].f5,
      });
    },
  },
};
