const proto = "https";
const domain = "www.mahjong-jp.com/drtest";
const pureUrl = `${proto}://${domain}`;
export const shareConfig = {
  url: encodeURIComponent(pureUrl),
  domain: domain,
  pureUrl: pureUrl,
  url_cn: encodeURIComponent("https://www.lizhimahjong.com/drtest"),
  title: "survey_bg.p28",
  text_1: "survey_bg.p36",
  text_2: "survey_bg.p37",
  tag_1: "survey_bg.p46",
  tag_2: "survey_bg.p47",
  // via: "", // Twitter account 未使用该参数
};
