<template>
  <div class="DoTest">
    <div class="tip_text_wrap">
      <img src="@/assets/image/survey/do_test/drh5_img.png" alt="" />
      <div v-if="refresh" class="tip_text">
        {{ $t(`survey_question.${currentQuestion.question}`) }}
      </div>
    </div>
    <div
      class="options"
      :class="{ three_options: currentQuestion.options.length === 3 }"
    >
      <a
        href="javascript:;"
        v-for="item in currentQuestion.options"
        :key="item.id"
        @click="selectOption(item)"
      >
        <div class="label">
          {{ $t(`survey_question.${item.label}`) }}
        </div>
      </a>
      <div
        class="revolver"
        :style="`transform:rotate(${(currentQuestSeq - 1) * 90}deg)`"
      >
        <img src="@/assets/image/survey/do_test/drh5_qna_img.png" alt="" />
      </div>
    </div>
    <div class="progress">
      <div class="red_line" :class="`red_line_${currentQuestSeq}`"></div>
      <div
        class="progress_item"
        :class="{ current: item === currentQuestSeq }"
        v-for="(item, index) in 4"
        :key="index"
      >
        <img
          v-if="item > currentQuestSeq"
          src="@/assets/image/survey/do_test/drh5_bar_dot_01.png"
          alt=""
        />
        <img
          v-else-if="item < currentQuestSeq"
          src="@/assets/image/survey/do_test/drh5_bar_dot_02.png"
          alt=""
        />
        <img
          v-else-if="item === currentQuestSeq"
          src="@/assets/image/survey/do_test/drh5_bar_dot_03.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { surveyList } from "../utils/data";
export default {
  name: "DoTest",
  props: {
    nextTo16: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      refresh: true,
      surveyList,
      currentId: 1,
      currentQuestSeq: 1,
    };
  },
  computed: {
    currentQuestion() {
      const quest = this.surveyList.find(({ id }) => id === this.currentId);
      return quest;
    },
  },
  mounted() {
    if (this.nextTo16) {
      this.currentId = 16;
      this.currentQuestSeq = 4;
    }
  },
  methods: {
    selectOption(item) {
      this.refresh = false;
      this.$nextTick(() => {
        if (item.next) {
          this.currentId = item.next;
          this.currentQuestSeq++;
          this.sendLog();
        } else if (item.result) {
          this.$emit("toResult", item.result);
        } else {
          console.log("selectOption error");
        }
        this.refresh = true;
      });
    },
    sendLog() {
      const f5Map = {
        2: "3", //第二题
        3: "4", //第三题
        4: "5", //第四题
        5: "6", //第五题
      };
      this.$emit("sendLog", {
        f3: "0",
        f4: "0",
        f5: f5Map[this.currentQuestSeq],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.DoTest {
  padding-top: 23rem;
  .tip_text_wrap {
    position: relative;
    margin-bottom: 8rem;
    margin-right: -1.6rem;
    img {
      position: absolute;
      right: -1rem;
      top: -17.5rem;
      width: 18.7rem;
    }
    .tip_text {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 71.5rem;
      height: 27.1rem;
      background-image: url("../../../assets/image/survey/do_test/drh5_qna_frame.png");
      background-repeat: no-repeat;
      background-size: cover;
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 4.8rem;
      padding-top: 4.4rem;
      padding-left: 2.6rem;
      padding-right: 6rem;
      padding-bottom: 6rem;
      text-align: center;
    }
  }
  .options {
    position: relative;
    text-align: center;
    margin-bottom: 14rem;
    &.three_options {
      margin-bottom: 6.3rem;
    }
    a {
      position: relative;
      z-index: 1;
      width: 40.4rem;
      height: 13.4rem;
      line-height: 13.4rem;
      background-image: url("../../../assets/image/survey/do_test/drh5_btn_chioceframe.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;
      font-size: 4.5rem;
      font-style: italic;
      font-weight: bold;
      color: #000000;
      margin-bottom: 2rem;
      &:active {
        background-image: url("../../../assets/image/survey/do_test/drh5_btn_chooseframe.png");
      }
      .label {
        transform: rotate(-6deg);
      }
    }
    .revolver {
      position: absolute;
      left: -42rem;
      top: -15rem;
      z-index: 0;
      width: 65rem;
      transition: all 0.3s;
    }
  }
  .progress,
  .red_line {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 57.4rem;
    height: 2.4rem;
    background-image: url("../../../assets/image/survey/do_test/drh5_bar_bg_01.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    .red_line {
      position: absolute;
      left: 0;
      top: 0;
      background-size: 57.4rem 2.4rem;
      background-image: url("../../../assets/image/survey/do_test/drh5_bar_bg_02.png");
      transition: all 0.5s;
      &.red_line_1 {
        width: 0;
      }
      &.red_line_2 {
        width: 19rem;
      }
      &.red_line_3 {
        width: 36rem;
      }
      &.red_line_4 {
        width: 57.4rem;
      }
    }
    .progress_item {
      position: relative;
      width: 3.9rem !important;
      // display: flex;
      // &:nth-child(2),
      // &:nth-child(3) {
      //   justify-content: center;
      // }
      // &:nth-child(4) {
      //   justify-content: flex-end;
      // }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3.9rem;
      }
      &.current {
        img {
          width: 9.4rem;
          max-width: none;
        }
      }
    }
  }
}
</style>
