<template>
  <div class="CommonDialog">
    <v-dialog
      v-model="showDialog"
      :content-class="`survey_dialog ${className}`"
      v-bind="$attrs"
    >
      <div class="survey_dialog_body">
        <a href="javascript:;" class="close_btn" @click="showDialog = false">
          <img src="@/assets/image/survey/dialog/drh5_btn_clo.png" alt="" />
        </a>
        <div class="title">
          <slot name="title"></slot>
        </div>
        <slot name="content"></slot>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CommonDialog",
  props: {
    value: Boolean,
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss">
.survey_dialog {
  width: 75rem !important;
  margin: 0;
  .survey_dialog_body {
    position: relative;
    width: 68.6rem;
    height: 102.1rem;
    // background-image: url("../../../assets/image/survey/dialog/drh5_popwin_panel.png");
    background-image: url("../../../assets/image/survey/dialog/drh5_popwin2_panel.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    overflow-x: hidden;
    .close_btn {
      position: absolute;
      right: -5rem;
      top: 1rem;
      z-index: 99;
      width: 16.8rem;
    }
    .title {
      width: 38rem;
      margin-left: 21.3rem;
      margin-bottom: 5rem;
    }
  }
}
</style>
