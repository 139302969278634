<template>
  <div class="StartTest">
    <div class="content">
      <div class="text_wrap">
        <p class="first">
          <span>{{ $t("survey_bg.p2") }}</span>
          <span class="high_light"> {{ $t("survey_bg.p3") }}</span>
          <span>{{ $t("survey_bg.p4") }}</span>
        </p>
        <p class="second">{{ $t("survey_bg.p5") }}</p>
        <p class="third">{{ $t("survey_bg.p6") }}</p>
      </div>
      <a href="javascript:;" class="start_btn" @click="toDoTest">
        {{ $t("survey_bg.p8") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartTest",
  methods: {
    toDoTest() {
      this.$emit("toDoTest");
    },
  },
};
</script>
<style lang="scss" scoped>
.StartTest {
  .content {
    width: 75rem;
    height: 113.9rem;
    background-image: url("../../../assets/image/survey/start_test/drh5_popwin_panel.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -27rem;
    padding: 41rem 11.2rem 0rem;
    .text_wrap {
      text-align: center;
      margin-bottom: 16rem;
      p {
        font-weight: bold;
        font-size: 3.5rem;
        line-height: 5rem;
        color: #ffffff;
        &.first,
        &.second {
          font-size: 4rem;
          line-height: 4.8rem;
        }
        &.second {
          font-size: 4rem;
          line-height: 4.8rem;
        }
        &.third {
          white-space: pre-line;
        }
        .high_light {
          color: #e2af04;
        }
      }
    }
    .start_btn {
      width: 43.3rem;
      height: 14.8rem;
      background-image: url("../../../assets/image/survey/start_test/drh5_btn_begintest.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;
      padding-top: 3.9rem;
      padding-left: 3rem;
      padding-right: 3rem;
      font-family: SourceHanSansSC-Bold;
      font-weight: bold;
      font-size: 4rem;
      line-height: 8rem;
      text-align: center;
      color: #1d1d1d;
    }
  }
}
</style>
